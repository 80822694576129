import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import {
    BsFillChatLeftDotsFill,
    BsFileSpreadsheet,
    BsFillFlagFill,
    BsNewspaper,
    BsLink, BsWhatsapp
} from 'react-icons/bs/index.esm.js';
import { HiOutlineUserGroup } from 'react-icons/hi/index.esm.js';
import { BiUser, BiLogOut, BiNews } from 'react-icons/bi/index.esm.js';
import { IoMdNotificationsOutline } from 'react-icons/io/index.esm.js';
import { MdOutlineQuiz } from 'react-icons/md/index.esm.js';
import { AiOutlineDashboard } from 'react-icons/ai/index.esm.js';
import './Sidebar.css';
import { useGlobalContext } from '../../Context/index.tsx';

const Sidebar = ({ children, showSidebar }) => {
    const { authDetails, setAuthDetails } = useGlobalContext();
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('user');
        setAuthDetails({});
        navigate('/login');
        window.location.reload();
    };

    const menuItem = [
        {
            path: '/dashboard',
            name: 'Dashboard',
            icon: <AiOutlineDashboard />,
        },
        {
            path: '/discussion',
            name: 'Discussion',
            icon: <BsFillChatLeftDotsFill />,
        },
        {
            path: '/newsfeed',
            name: 'News Feed',
            icon: <BiNews />,
        },
        {
            path: '/reports',
            name: 'Reports',
            icon: <BsFileSpreadsheet />,
        },
        {
            path: '/banner',
            name: 'Banner',
            icon: <BsFillFlagFill />,
        },
        {
            path: '/user',
            name: 'User',
            icon: <BiUser />,
        },
        {
            path: '/club',
            name: 'Club',
            icon: <HiOutlineUserGroup />,
        },
        {
            path: '/notification',
            name: 'Notification',
            icon: <IoMdNotificationsOutline />,
        },
        {
            path: '/quiz',
            name: 'Quiz',
            icon: <MdOutlineQuiz />,
        },
        {
            path: '/plexusmd',
            name: 'PlexusMD',
            icon: <BsNewspaper />,
        },
        {
            path: '/ad-stats-1',
            name: 'Ad-Stats (Gen)',
            icon: <BsFileSpreadsheet />,
        },
        {
            path: '/linkzap',
            name: 'Link Zap',
            icon: <BsLink />,
        },
        {
            path: '/wa-template-helper',
            name: 'WA Template',
            icon: <BsWhatsapp />,
        },
    ];

    const renderListOfMenuItems = menuItems => {
        return menuItems.map((menuItem, idx) => (
            <NavLink
                to={menuItem.path}
                className="list"
                key={idx}
                activeClassName="active"
            >
                <div className="icon">{menuItem.icon}</div>
                <div className="link_text">{menuItem.name}</div>
            </NavLink>
        ));
    };

    return (
        <div className={`main-container ${showSidebar ? 'show-sidebar' : ''}`}>
            <div className="sidebar-container">
                <div className="logo-info">
                    <img
                        src="https://plexusmd.gumlet.io/MedflixLogoNEWFINAL26Sep_1695792411077.png"
                        alt="Medflix Logo"
                    />
                </div>
                <div className="menu-list" style={{overflowY: 'scroll' , maxHeight: '70%'}}>
                    {renderListOfMenuItems(menuItem)}
                    <Button
                        to={'/login'}
                        className="logout-btn"
                        onClick={logout}
                    >
                        {authDetails?.picture && (
                            <Image
                                roundedCircle={true}
                                src={authDetails?.picture || ''}
                                style={{ width: '2em' }}
                                alt="User Avatar"
                            />
                        )}
                        <div className="icon">
                            <BiLogOut />
                        </div>
                        <div className="link_text">Logout</div>
                    </Button>
                </div>
            </div>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;
