import React, { useState, useEffect } from 'react';
import {  Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { getActiveAds } from './api';
import axios from 'axios';
import { globalURLS } from '../../Global/urls.js';
import './AdsDash.css';

const downloadOptions = {
    'account-wise' : {
        name: "Accountid wise",
        api: 'addash1_get_adstats2'
    },
    'day-wise' : {
        name: "Day wise",
        api: 'addash1_get_adstats3'
    }
};

const Dropdown: React.FC<{ onSelect: (id: string) => void }> = ({ onSelect }) => {
    const [ads, setAds] = useState<[] | [ {id: string, name: string }]>([]);
    const [selectedAd, setselectedAd] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<string>(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]);
    const [toDate, setToDate] = useState<string>(new Date().toISOString().split('T')[0]);

    useEffect(() => {
        getActiveAds().then(setAds);
    }, []);

    const downloadReport = async (key:string) => {
        console.log('d', downloadOptions[key]);
        if (isLoading) return;
        setIsLoading(true);
        try {
            const response = await axios.post(`${globalURLS.baseURL}${downloadOptions[key].api}`, { ad_id: selectedAd, startdate: fromDate, enddate: toDate });
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `report-${key}-${Date.now()}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error generating bulk links:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{flexDirection:'row', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <div className="dropdown">
                <select onChange={(e) => {
                    onSelect(e.target.value);
                    setselectedAd(e.target.value);
                }}>
                    <option value="">Select an Ad</option>
                    {ads.map(({id, name}) => (
                        <option
                            key={id}
                            value={id}
                        >
                            {name}
                        </option>
                    ))}
                </select>
            </div>

            <div style={{display:'flex', flexDirection: 'column', alignItems: 'end', flex: 1, justifyContent:'end'}}>
                {!!selectedAd && (
                    <div className="lz_filters1">
                        <p>Choose Dates for Reports:</p>
                        <InputGroup>
                            <Form.Control type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                            <Form.Control type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                        </InputGroup>
                    </div>
                )}

                {!!selectedAd && (
                    <div style={{width: 150, marginTop: 20}}>
                        <select onChange={(e) => { downloadReport(e.target.value); }} value={""}>
                            <option value="">{isLoading ? 'Downloading...' : 'Download Report'}</option>
                            {Object.keys(downloadOptions).map((key: string) => (
                                <option
                                    key={key}
                                    value={key}
                                >
                                    {downloadOptions[key].name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dropdown;
