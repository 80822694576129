// src/App.tsx

import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Spinner,
  Alert,
} from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { globalURLS } from '../../Global/urls.js';

interface DataItem {
  prim: number;
  templateid: string;
  type: string;
  discussionid: string;
  conferenceid: string;
}

const App: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Edit Modal State
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [currentEdit, setCurrentEdit] = useState<DataItem | null>(null);
  const [editValues, setEditValues] = useState<Partial<DataItem>>({});

  // Delete Confirmation Modal State
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<DataItem | null>(null);

  // Create Modal State
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [createValues, setCreateValues] = useState<Partial<DataItem>>({
    templateid: '',
    type: 'discussion_register',
    discussionid: '',
    conferenceid: '',
  });

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.post<{success: boolean, data: DataItem[]}>(`${globalURLS.baseURL}sinch_helper1_fetchall`);
      if (!response.data.success) throw new Error("Some problem occurred");
      setData(response.data.data);
      setLoading(false);
    } catch (err: any) {
      setError(err.message || 'Error fetching data');
      setLoading(false);
    }
  };

  // Handle Edit Button Click
  const handleEditClick = (item: DataItem) => {
    setCurrentEdit(item);
    setEditValues({
      type: item.type,
      discussionid: item.discussionid,
      conferenceid: item.conferenceid,
    });
    setShowEditModal(true);
  };

  // Handle Delete Button Click
  const handleDeleteClick = (item: DataItem) => {
    setDeleteItem(item);
    setShowDeleteConfirm(true);
  };

  // Handle Create Button Click
  const handleCreateClick = () => {
    setCreateValues({
      templateid: '',
      type: 'discussion_register',
      discussionid: '',
      conferenceid: '',
    });
    setShowCreateModal(true);
  };

  // Close Edit Modal
  const handleEditModalClose = () => {
    setShowEditModal(false);
    setCurrentEdit(null);
    setEditValues({});
  };

  // Close Delete Confirmation Modal
  const handleDeleteConfirmClose = () => {
    setShowDeleteConfirm(false);
    setDeleteItem(null);
  };

  // Close Create Modal
  const handleCreateModalClose = () => {
    setShowCreateModal(false);
    setCreateValues({
      templateid: '',
      type: 'discussion_register',
      discussionid: '',
      conferenceid: '',
    });
  };

  // Handle Edit Input Change
  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setEditValues({
      ...editValues,
      [name]: value,
    });
  };

  // Handle Create Input Change
  const handleCreateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCreateValues({
      ...createValues,
      [name]: value,
    });
  };

  // Submit Edit
  const handleEditSubmit = async () => {
    if (!currentEdit) return;

    const updatedData: DataItem = {
      prim: currentEdit.prim,
      templateid: currentEdit.templateid,
      type: editValues.type,
      discussionid: editValues.discussionid,
      conferenceid: editValues.conferenceid
    };

    try {
      await axios.post(`${globalURLS.baseURL}sinch_helper1_update`, updatedData);
      handleEditModalClose();
      fetchData();
    } catch (err: any) {
      alert(err.message || 'Error updating data');
    }
  };

  // Submit Delete
  const handleDelete = async () => {
    if (!deleteItem) return;

    try {
      await axios.post(`${globalURLS.baseURL}sinch_helper1_delete`, { prim: deleteItem.prim });
      handleDeleteConfirmClose();
      fetchData();
    } catch (err: any) {
      alert(err.message || 'Error deleting data');
    }
  };

  // Submit Create
  const handleCreateSubmit = async () => {
    const newData: Omit<DataItem, 'prim'> = {
      templateid: createValues.templateid || '',
      type: createValues.type || 'discussion_register',
      discussionid: createValues.discussionid || '',
      conferenceid: createValues.conferenceid || '',
    };
    if (newData.templateid && newData.type) {
      try {
        await axios.post(`${globalURLS.baseURL}sinch_helper1_create`, newData);
        handleCreateModalClose();
        fetchData();
      } catch (err: any) {
        alert(err.message || 'Error creating data');
      }
    }
  };

  if (loading) {
    return (
      <div className="container mt-5 text-center">
        <Spinner animation="border" role="status" />
        <span className="ms-2">Loading...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <Alert variant="danger">Error: {error}</Alert>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      {/* Heading */}
      <h2 className="mb-4" style={{color: 'black'}}>WA (Sinch) Auto-Registration Template Mappings</h2>

      {/* Create New Button */}
      <Button variant="success" className="mb-3" onClick={handleCreateClick}>
        <FaPlus className="me-2" />
        Create New
      </Button>

      {/* Data Table */}
      <Table bordered hover responsive>
        <thead>
          <tr>
            <th>Template ID</th>
            <th>Type</th>
            <th>Discussion ID</th>
            <th>Conference ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.prim}>
              <td>{item.templateid}</td>
              <td>{item.type}</td>
              <td>{item.discussionid}</td>
              <td>{item.conferenceid}</td>
              <td>
                <Button
                  variant="primary"
                  size="sm"
                  className="me-2"
                  onClick={() => handleEditClick(item)}
                >
                  <FaEdit />
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteClick(item)}
                >
                  <FaTrash />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={handleEditModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentEdit && (
            <Form>
              <Form.Group className="mb-3" controlId="formTemplateId">
                <Form.Label>Template ID</Form.Label>
                <Form.Control
                  type="text"
                  value={currentEdit.templateid}
                  readOnly
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formType">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  name="type"
                  value={editValues.type}
                  onChange={handleEditChange}
                >
                  <option value="discussion_register">
                    discussion_register
                  </option>
                  <option value="conference_register">
                    conference_register
                  </option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formDiscussionId">
                <Form.Label>Discussion ID</Form.Label>
                <Form.Control
                  type="text"
                  name="discussionid"
                  value={editValues.discussionid}
                  onChange={handleEditChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formConferenceId">
                <Form.Label>Conference ID</Form.Label>
                <Form.Control
                  type="text"
                  name="conferenceid"
                  value={editValues.conferenceid}
                  onChange={handleEditChange}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteConfirm} onHide={handleDeleteConfirmClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteItem && (
            <p>
              Are you sure you want to delete the template with ID:{' '}
              <strong>{deleteItem.templateid}</strong>?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteConfirmClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Create Modal */}
      <Modal show={showCreateModal} onHide={handleCreateModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="createTemplateId">
              <Form.Label>Template ID</Form.Label>
              <Form.Control
                type="text"
                name="templateid"
                value={createValues.templateid}
                onChange={handleCreateChange}
                placeholder="Enter Template ID"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="createType">
              <Form.Label>Type</Form.Label>
              <Form.Select
                name="type"
                value={createValues.type}
                onChange={handleCreateChange}
              >
                <option value="discussion_register">
                  discussion_register
                </option>
                <option value="conference_register">
                  conference_register
                </option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="createDiscussionId">
              <Form.Label>Discussion ID</Form.Label>
              <Form.Control
                type="text"
                name="discussionid"
                value={createValues.discussionid}
                onChange={handleCreateChange}
                placeholder="Enter Discussion ID"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="createConferenceId">
              <Form.Label>Conference ID</Form.Label>
              <Form.Control
                type="text"
                name="conferenceid"
                value={createValues.conferenceid}
                onChange={handleCreateChange}
                placeholder="Enter Conference ID"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreateModalClose}>
            Close
          </Button>
          <Button variant="success" onClick={handleCreateSubmit}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default App;
