import { ReportsData } from './interface';

const adminAPIdData: ReportsData[] = [
    {
        name: 'Discussion Attendee Stats',
        description: 'This API is used to get Discussion Attendee Stats data.',
        averageTime: 0.7,
        endPoint: 'getDiscussionAtendeeStats',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'dropdown',
                reqBody: {
                    label: 'includeTestAccounts',
                    placeHolder: 'Include Test Accounts',
                    required: true,
                    value: '',
                    filterBy: ['true', 'false'],
                },
            },
        ],
    },
    {
        name: 'BNPL Stats',
        description: 'This API is used to get BNPL Stats.',
        averageTime: 0.7,
        endPoint: 'ADgetBNPLReport',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Video Ads Stats',
        description: 'This API is used to get Video Ads Stats.',
        averageTime: 0.7,
        endPoint: 'ADgetVideoAdStats',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Add Spot Stats',
        description: 'This API is used to get Add Spot Stats.',
        averageTime: 0.7,
        endPoint: 'ADgetAdSpotStats1',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Ad-Spots View By Accounts',
        description: 'This API is used to get Ad-Spots View+Clicks Accounts.',
        averageTime: 0.7,
        endPoint: 'ADgetAdSpotsViewsClicksAccountIDWise',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'In Room Rating Stats',
        description: 'This API is used to get In Room Rating Stats.',
        averageTime: 0.7,
        endPoint: 'ADgetInRoomRatingStats',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Verification documents',
        description: 'This API is used to get users data',
        averageTime: 0.3,
        endPoint: 'ADgetAllUsersForVerification',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Live Attendees At TS',
        description:
            'This API is used to get  Live Attendees at given timestamp',
        averageTime: 0.3,
        endPoint: 'ADgetLiveAttendeesAtTS',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'datetime',
                reqBody: {
                    label: 'timestamp',
                    placeHolder: 'timestamp',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Live Attendees At TimePeriod',
        description: 'This API is used to get Live Attendees At TimePeriod',
        averageTime: 0.3,
        endPoint: 'ADgetLiveAttendeesAtTimePeriod',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'datetime',
                reqBody: {
                    label: 'from_timestamp',
                    placeHolder: 'from_timestamp',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'datetime',
                reqBody: {
                    label: 'to_timestamp',
                    placeHolder: 'to_timestamp',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'User Data',
        description:
            'Get user details with mobile number or account id, accountid or combination of callingcode and phone should be used',
        averageTime: 0.2,
        endPoint: 'ADgetUserData',
        isDownloadable: false,
        error: 'Enter accountid or combination of callingcode and phone',
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'accountid',
                    placeHolder: 'accountid',
                    required: false,
                    value: '',
                },
            },
            {
                type: 'text',
                reqBody: {
                    label: 'phone',
                    placeHolder: 'phone',
                    required: false,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'NewsFeed Poll Response',
        description: 'Get  NewsFeed Poll Users Response data',
        averageTime: 0.2,
        endPoint: 'ADgetNewsFeedPollUsersResponse',
        isDownloadable: false,
        error: 'Enter accountid or combination of callingcode and phone',
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'id',
                    placeHolder: 'id',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Club users data',
        description: 'This API is used to get Club users data.',
        averageTime: 0.7,
        endPoint: 'getClubMemberStats',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'clubid',
                    placeHolder: 'Clubid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Live mins spent user wise',
        description:
            'This API is used to get Live mins spent user wise. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'ADgetDiscAttendeeTimeStats',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Newsfeeds Basic Stats (select not more than 30days difference)',
        description:
            'This API is used to get Newsfeeds Basic Stats. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'ADgetFeedStats2',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Newsfeeds Vote Stats (Quiz/Questionnaire)',
        description:
            'This API is used to get Newsfeeds Vote Stats (Quiz/Questionnaire).',
        averageTime: 0.7,
        endPoint: 'ADgetFeedStats3',
        isDownloadable: true,
        inputs: [],
    },
    {
        name: 'Replay mins spent user wise',
        description:
            'This API is used to get Replay mins spent user wise. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'ADgetRecordingAccTimeStats',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'discussionid',
                    required: false,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'BNPL Report',
        description:
            'This API is used to get BNPL Report. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'ADgetBNPLReport',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Discussion and clubid mapping',
        description:
            'This API is used to get Discussion and clubid mapping. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'getClubAndDiscussionIdMapping',
        isDownloadable: true,
        inputs: [
            {
                type: 'dropdown',
                reqBody: {
                    label: 'mappingFor',
                    placeHolder: 'Mapping For',
                    required: true,
                    value: '',
                    filterBy: ['club', 'discussion'],
                },
            },
        ],
    },
    {
        name: 'RCP Events',
        description:
            'This API is used to get RCP events by dates. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'ADrcpEventsBetweenDates',
        isDownloadable: true,
        inputs: [
            {
                type: 'dropdown',
                reqBody: {
                    label: 'rcpEvent',
                    placeHolder: 'RCP Event',
                    required: true,
                    value: '',
                    filterBy: ['RCP'],
                },
            },
            {
                type: 'date',
                minDate: '2023-10-10',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                minDate: '2023-10-10',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Home screen load account ids in a timeframe',
        description:
            'This API is used to get Home screen load account ids in a timeframe. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'getAllEventsByDates',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Get Any Event Stats',
        description:
            'This API is used to get Get Any Event Stats. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'getAllEventsByDates',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'text',
                reqBody: {
                    label: 'event',
                    placeHolder: 'event',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'User eligibility for a discussion ',
        description:
            'This API is used to getUser eligibility for a discussion.',
        averageTime: 0.7,
        endPoint: 'ADgetUserDiscussionData',
        isDownloadable: false,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'text',
                reqBody: {
                    label: 'accountid',
                    placeHolder: 'Accountid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Banner Click Stats',
        description: 'This API is used to Banner Click Stats',
        averageTime: 0.7,
        endPoint: 'ADgetAllBannerClicks',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'bannerid',
                    placeHolder: 'Bannerid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Quiz Responses',
        description: 'This API is used to get quiz responses',
        averageTime: 0.7,
        endPoint: 'ADgetQuizResponses',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'quizid',
                    placeHolder: 'quizid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Users eligibility for a discussion ',
        description:
            'This API is used to get Users eligibility for a discussion.',
        averageTime: 0.7,
        endPoint: 'ADgetUsersEligibilityForDisussion',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Quiz Users',
        description:
            'This API is used to get all user data rankwise for a discussion.',
        averageTime: 0.7,
        endPoint: 'ADgetAllQuizData',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Discussion Feedback Stats',
        description: 'This API is used to discussion feed back stats',
        averageTime: 0.7,
        endPoint: 'getDiscussionFeedbackStats',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Discussion Poll Data',
        description: 'This API is used to discussion poll data',
        averageTime: 0.7,
        endPoint: 'ADgetDiscPollData',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'NPS Score',
        description:
            'This API is used to get NPS Score. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'getADNPSScore',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Banners Click Stats (Date wise)',
        description:
            'This API is used to get Banners Click Stats. Please do select both the startDate and endDate.',
        averageTime: 0.7,
        endPoint: 'ADgetBannerClickStats',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Discussion Certificates',
        description: 'This API is used to get Discussion Certificates.',
        averageTime: 0.7,
        endPoint: 'ADgetDiscussionCertificates',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startDate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'endDate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'text',
                reqBody: {
                    label: 'discussionid',
                    placeHolder: 'Discussionid',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'dropdown',
                reqBody: {
                    label: 'getby',
                    placeHolder: 'Get By',
                    required: true,
                    value: '',
                    filterBy: ['discussion', 'date'],
                },
            },
        ],
    },
    {
        name: 'User Basic Stats 1',
        description: 'This API is used to get User Basic Stats 1',
        averageTime: 0.7,
        endPoint: 'AD_userbasicstats1',
        isDownloadable: true,
        inputs: [
        ],
    },
    {
        name: 'Get Latest Datasets',
        description: 'This API is used to get all latest datasets',
        averageTime: 0.7,
        endPoint: 'ADgetAllDatasets',
        isDownloadable: true,
        inputs: [
        ],
    },
    {
        name: 'Get Assessment Responses',
        description: 'This API is used to get Assessment Responses',
        averageTime: 0.7,
        endPoint: 'ADgetAssessmentResponses',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'assessmentid',
                    placeHolder: 'Assessmentid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Get Admin Report',
        description: 'This API is used to get Admin Report for specific users',
        averageTime: 0.7,
        endPoint: 'getAdminReportBasic_T1',
        isDownloadable: true,
        inputs: [
            {
                type: 'dropdown',
                reqBody: {
                    label: 'report_type',
                    placeHolder: 'Type',
                    required: true,
                    value: 'faculty',
                    filterBy: ['faculty', 'international'],
                },
            },
        ],
    },
    {
        name: 'Banner View Stats',
        description: 'This API is used to Banner View Stats',
        averageTime: 0.7,
        endPoint: 'ADgetAllBannerViewStats',
        isDownloadable: true,
        inputs: [
            {
                type: 'text',
                reqBody: {
                    label: 'bannerid',
                    placeHolder: 'Bannerid',
                    required: true,
                    value: '',
                },
            },
        ],
    },
    {
        name: 'Get MAU stats 1',
        description:
            'This API is used to get MAU accountids, platform wise',
        averageTime: 0.7,
        endPoint: 'ADgetMAU1',
        isDownloadable: true,
        inputs: [
            {
                type: 'date',
                reqBody: {
                    label: 'startdate',
                    placeHolder: 'Start Date',
                    required: true,
                    value: '',
                },
            },
            {
                type: 'date',
                reqBody: {
                    label: 'enddate',
                    placeHolder: 'End Date',
                    required: true,
                    value: '',
                },
            },
        ],
    },
];

export default adminAPIdData;
